/* eslint-disable react/forbid-elements */
import { FC } from 'react';
import { Typography, Grid, Button, Link, Divider, useTheme, useMediaQuery, Stack } from '@mui/material';
import { eventslSX, sectionHeaderSX } from '@/components/content/NewsEvent/styles/newsEvents';
import { ID } from '@/data/types/Basic';
import { useLocalization } from '@/data/Localization';
import { useEventsURL } from '@/data/Content/EventsURL';
import { useGetNewsWidget } from '@/data/Content/NewsWidget';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { articleLinksSX } from '@/components/content/NewsArticle/styles/links';
import { useGetEventsSimple } from '@/data/Content/NewsEvents';
import { ProgressIndicator } from '@/components/blocks/ProgressIndicator';
import { removeSpecialChars } from '@/utils/formatString';

export const NewsEvent: FC<{
    id: ID;
}> = () => {
    const localization = useLocalization('NewsEvents');
    const articleNLS = useLocalization('NewsArticle');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { eventURL } = useEventsURL();
    const { news, isLoadingNews } = useGetNewsWidget('simpleList');
    const { eventsBySimple, eventsBySimpleisLoading } = useGetEventsSimple('3');

    const formatNewsDate = (publishDate: string | null | undefined): string => {
        if (!publishDate) {
            return articleNLS.NotPopulated.t();
        }
        const date = new Date(publishDate);
        const month = date.toLocaleString('default', { month: 'long' });
        const day = date.getDate();
        const year = date.getFullYear();

        return `${month} ${day}, ${year}`;
    };

    const formatDate = (dateString: string | null | undefined): string => {
        if (!dateString) {
            return articleNLS.NotPopulated.t();
        }
        const dateParts = dateString.split('/');
        const day = parseInt(dateParts[1], 10);
        const month = parseInt(dateParts[0], 10);
        const year = parseInt(dateParts[2], 10);
        const date = new Date(year, month - 1, day);
        const formattedDate = date.toLocaleDateString('en-US', { day: '2-digit', month: 'long', year: 'numeric' });
        return formattedDate;
    };

    const createArticleHref = (inputID: string | null | undefined, title: string | null | undefined) => {
        if (!inputID) {
            return '/home';
        }
        return `/news/article/${removeSpecialChars(title as string)}_${inputID}`;
    };

    return (
        (isLoadingNews || eventsBySimpleisLoading) ? (
            <ProgressIndicator />
        ) : (
            <Grid container sx={{ margin: '30px 0' }}>
                <Grid item xs={12} sm={6} sx={{ padding: { xs: '0 10px', sm: '0 10px', md: '0 90px 0 0' } }}>
                    <Grid item xs={12} sx={{ marginBottom: '20px' }}>
                        <Typography sx={sectionHeaderSX} gutterBottom>
                            {localization.NewsRegulatoryAlert.t()}
                        </Typography>
                    </Grid>

                    {news?.map((item, index: number) => (
                        <Grid item xs={12} key={index} sx={eventslSX}>
                            <Typography variant="h6">{formatNewsDate(item.PublishDate)}</Typography>
                            <Typography variant="h6">{item.Title ? item.Title : articleNLS.NotPopulated.t()}</Typography>
                            <Link href={createArticleHref(item.ID, item.Title)} variant="blue-link-chevron" sx={articleLinksSX}>
                                <Stack direction="row" alignItems='center'>
                                    {localization.RegisterNow.t()}
                                    <FontAwesomeIcon style={{ height: '12px', width: '12px', marginLeft: '0.1rem' }} icon={faAngleRight} />
                                </Stack>
                            </Link>
                            {index !== 2 ? <Divider /> : null}
                        </Grid>
                    ))}
                    <Grid item xs={12} >
                        <Button variant="blue-contained" href="news" sx={{ marginTop: '20px', padding: '8px 16px 8px 16px' }}>
                            {localization.ViewAllNewsEvents.t()}
                            <FontAwesomeIcon style={{ height: '14px', width: '14px', marginLeft: '0.1rem' }} icon={faAngleRight} />
                        </Button>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={6} marginTop={isMobile ? '16px' : '0'} sx={{ padding: { xs: '0 10px', sm: '0 10px', md: '0 0 0 90px !important' } }}>
                    <Grid item xs={12} sx={{ marginBottom: '20px' }}>
                        <Typography sx={sectionHeaderSX} gutterBottom>{localization.Events.t()}</Typography>
                    </Grid>
                    {eventsBySimple?.map((item, index: number) => (
                        <Grid item xs={12} key={index} sx={eventslSX}>
                            {item?.startTime !== '' && item?.endTime !== '' ?
                                <Typography variant="h6">{formatDate(item?.startDate)}<br />{item?.startTime}{`-`}{item?.endTime}</Typography> :
                                <Typography variant="h6">{localization.AvailableThrough.t()}<br />{formatDate(item?.endDate)}</Typography>
                            }
                            <Typography variant="h6">{item?.title}</Typography>
                            <Link href={item?.url} target='_blank' variant="blue-link-chevron" sx={articleLinksSX}>
                                <Stack direction="row" alignItems='center'>
                                    {item?.urlText}
                                    <FontAwesomeIcon style={{ height: '12px', width: '12px', marginLeft: '0.1rem' }} icon={faAngleRight} />
                                </Stack>
                            </Link>
                            {index !== 2 ? <Divider /> : null}
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <Button variant="blue-contained" href={eventURL} sx={{ marginTop: '20px', padding: '8px 16px 8px 16px' }}>
                            {localization.ViewAllEvents.t()}
                            <FontAwesomeIcon style={{ height: '14px', width: '14px', marginLeft: '0.1rem' }} icon={faAngleRight} />
                        </Button>
                    </Grid>
                </Grid>
            </Grid >
        )
    );
};
