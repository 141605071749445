/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material';

export const articleLinksSX: SxProps<Theme> = {
	'&:hover': {
		color: 'button.blue',
		'text-underline-offset': '3px',
	},
};

export const vertDividerSX: SxProps<Theme> = {
	bgcolor: '#D8DBDD !important',
	color: '#D8DBDD !important',
	opacity: '0.6',
};
