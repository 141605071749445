import { SxProps, Theme } from '@mui/material';

export const eventslSX: SxProps<Theme> = () => ({
	padding: '10px',
	h6: { fontSize: '14px', fontWeight: '400', marginBottom: '16px' },
	a: { marginBottom: '16px', display: 'inline-block', fontSize: '14px', fontWeight: '600' },
});

export const sectionHeaderSX: SxProps<Theme> = () => ({
	fontSize: '30px',
	fontWeight: '600',
	color: 'heading.dark',
});
